import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

export function CommApp(props) {
    const data = useStaticQuery(gql_commApp);

    return (
        <LayoutSection bg="secondaryBackground" my="0">
            <ContentWidth py={{ _: '6rem', lg: '6rem' }}>
                <Flex width="100%" direction={{ _: 'column', md: 'row' }}>
                    <Flex
                        py={{ _: 16, lg: 32, full: 40 }}
                        mr={{ _: 0, md: 16 }}
                        direction="column"
                        width={{ _: 'auto', md: '40%' }}
                        flexGrow="1"
                        justifyContent="center"
                    >
                        <Heading fontSize="subsectionHeading" mb={6} as="h2">
                            A native mobile app
                        </Heading>

                        <Paragraph
                            lineHeight="taller"
                            mb={10}
                            color="bodyText"
                            maxWidth="600px"
                        >
                            Put your community in the palm of people's hands and
                            open up more possibilities.
                        </Paragraph>
                        <Flex direction={{ _: 'row' }}>
                            <Button
                                mr={{ _: '10px' }}
                                mb={{ _: '10px', sm: '0' }}
                                href="/contact?source=apppage"
                            >
                                Get Started
                            </Button>
                            <Button href="/methods/app">Learn More</Button>
                        </Flex>
                    </Flex>
                    <Box width={{ _: '80%', md: '30%' }} mx="auto" flexGrow="1">
                        <Img alt="" fluid={data.hero.childImageSharp.fluid} />
                    </Box>
                </Flex>
            </ContentWidth>
        </LayoutSection>
    );
}

const gql_commApp = graphql`
    query CommApp {
        hero: file(relativePath: { eq: "images/home/app.png" }) {
            childImageSharp {
                fluid(maxWidth: 550, srcSetBreakpoints: [550, 850, 1100]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
