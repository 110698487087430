import { Layout } from 'components/Layout';
import { OurClients } from 'components/OurClients';
import React from 'react';
import { Methods } from '../components/Methods/Methods';
import { CommApp } from './CommApp';
import { Contact } from './Contact';
import { FeaturedStory } from './FeaturedStory';
import { HowWeHelp } from './HowWeHelp';
import { PrinciplesSection } from './Principles';
import { Section3 } from './Section3';
import { Success } from './Success';
import { Testimonials } from './Testimonials';
import { VideoHero } from './VideoHero';

export const Home = () => {
    return (
        <Layout
            pageTitleFull="Building communities with Audentio"
            siteDescription="We can help grow your community whether you’re on YouTube, a forum, or at a digital conference."
        >
            {/* <Hero /> */}

            <VideoHero />

            <OurClients noBorder />

            <HowWeHelp />

            <Section3 />

            <CommApp />

            <Success />

            <Testimonials />

            <PrinciplesSection />

            <Methods
                bg={{
                    _: 'primaryBackground',
                    lg: 'secondaryBackground',
                }}
                pt={{
                    _: null,
                    lg: 0,
                }}
            />

            <FeaturedStory />

            <Contact />
        </Layout>
    );
};
