import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { ThemeProvider } from 'components/ThemeProvider';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box } from '../Box';

const query = graphql`
    query Home_Clients {
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export const OurClients = ({noBorder = null, ...props}) => {
    const data = useStaticQuery(query);
    const clients = [
        { name: 'IGN', image: data.ign },
        { name: 'cPanel', image: data.cpanel },
        { name: 'Yahoo!', image: data.yahoo },
        { name: 'MacRumors', image: data.macrumors },
        { name: "Tom's hardware", image: data.tomshardware },
    ];

    return (
        <ContentWidth>
            <LayoutSection
                mt={0}
                mb={ noBorder ? '8rem' : '16'}
                pb={ noBorder ? '0' : '16'}
                style={{ borderBottom: noBorder ? '0px' : '2px solid #eee' }}
                alignItems={{ _: 'center', xl: 'flex-start' }}
            >
                <Box
                    display="flex"
                    flexDirection={{ _: 'column', xl: 'row' }}
                    alignItems="center"
                >
                    <ThemeProvider defaultMode="dark">
                        <Box
                            width={{ _: 'auto', xl: '280px' }}
                            flexShrink={0}
                            paddingX={12}
                            paddingY={16}
                            backgroundColor="primaryBackground"
                            mb={{ _: 20, xl: 0 }}
                        >
                            <Heading mb={0} fontSize="paragraphTitle">
                                we've been building communities for over 10
                                years
                            </Heading>
                        </Box>
                    </ThemeProvider>

                    <Flex
                        flexGrow={1}
                        direction="row"
                        flexWrap="wrap"
                        mt={{ _: -10, xl: 0 }}
                        ml={{ xl: 5 }}
                        justifyContent={{ _: 'center', xl: 'flex-start' }}
                    >
                        {clients.map(({ name, image }, index) => {
                            return (
                                <Flex
                                    key={index}
                                    // paddingX={10}
                                    align="center"
                                    flexBasis={{ _: 170, md: 196 }}
                                    m={-5}
                                    // flexShrink={1}
                                    // mb={{ _: 10, xl: 0 }}
                                >
                                    <div>
                                        <img
                                            alt={name}
                                            width="100%"
                                            height="auto"
                                            src={image.publicURL}
                                        />
                                    </div>
                                </Flex>
                            );
                        })}
                    </Flex>
                </Box>
            </LayoutSection>
        </ContentWidth>
    );
}
