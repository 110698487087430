import { Box } from 'components/Box';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { SmallSubtitle } from 'components/SmallSubtitle';
import { ThemeProvider } from 'components/ThemeProvider';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const ImageContainer = ({ fluid, label, ...rest }) => (
    <Box position="relative" {...rest}>
        <Img
            fluid={fluid}
            // style={[{ width: '100%' }, imgStyle]}
            imgStyle={{ height: 'auto' }}
        />

        {label && (
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                zIndex={1}
                height="100%"
                background="linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 36%)"
            />
        )}

        {label && (
            <Box
                position="absolute"
                bottom={4}
                right={4}
                zIndex={2}
                color="white"
            >
                <SmallSubtitle>{label}</SmallSubtitle>
            </Box>
        )}
    </Box>
);

export const Section3 = () => {
    const data = useStaticQuery(query);

    return (
        <ThemeProvider>
            <LayoutSection
                my="0"
                hasPadding
                align="center"
                backgroundColor="primaryBackground"
            >
                <ContentWidth>
                    <Flex direction="column" align="center">
                        <SmallSubtitle mb={5}>
                            Connecting people and passions
                        </SmallSubtitle>

                        <Heading
                            mb={20}
                            center
                            textAlign="center"
                            fontSize={{
                                _: 'subtitle',
                                lg: 'sectionHeading',
                            }}
                            as="h2"
                        >
                            From lifestyle brands, community{' '}
                            <Box as="br" display={{ _: 'none', md: 'block' }} />
                            managers, hobbyists, to local initiatives
                        </Heading>
                    </Flex>

                    <Flex
                        maxWidth={{ _: '100%', sm: '70%', lg: '100%' }}
                        mx="auto"
                        direction={{ _: 'column', md: 'row' }}
                        flexWrap={{ _: 'nowrap', md: 'wrap', lg: 'nowrap' }}
                        justify="center"
                    >
                        {/* left */}
                        <Flex
                            width={{
                                _: '100%',
                                md: 'calc(100% - 240px)',
                                lg: 'calc(45% - 150px)',
                            }}
                            height={{ _: 'auto', lg: 0 }}
                            direction="row"
                            flexWrap="wrap"
                            align="flex-end"
                            justify="flex-end"
                            flexgrow={0}
                            mr={{ _: '0', md: 10, lg: 0 }}
                            mb={{ _: '0', md: 10, lg: 0 }}
                        >
                            <ImageContainer
                                label="Influencers"
                                fluid={data.influencer.childImageSharp.fluid}
                                width="45%"
                                mr="5%"
                            />

                            <ImageContainer
                                label="Adventurers"
                                fluid={data.adventurer.childImageSharp.fluid}
                                width="50%"
                            />

                            <ImageContainer
                                label="Musicians"
                                fluid={data.musician.childImageSharp.fluid}
                                width={{ _: '100%', lg: '80%' }}
                                mt="5%"
                            />
                        </Flex>

                        <Flex align="flex-end" direction="row" flexWrap="wrap">
                            <ImageContainer
                                label="Fashion brands"
                                fluid={data.purse.childImageSharp.fluid}
                                width={{ _: '100%', md: 210, lg: 250 }}
                                position="relative"
                                top={{ lg: '-15%' }}
                                mx={{ _: 0, lg: 10 }}
                                my={{ _: 10, lg: 0 }}
                            />
                        </Flex>

                        {/* right */}
                        <Flex
                            width={{ _: '100%', lg: 'calc(55% - 150px)' }}
                            direction="row"
                            flexWrap="wrap"
                            align="flex-start"
                            justify="flex-start"
                            // height={{ _: 'auto', lg: 0 }}
                        >
                            <ImageContainer
                                label="Outdoorsmen"
                                fluid={data.outdoorsmen.childImageSharp.fluid}
                                width={{ _: '100%', lg: '80%' }}
                                mb="5%"
                            />

                            <ImageContainer
                                label="Tech Enthusiasts"
                                fluid={
                                    data.techEnthusiats.childImageSharp.fluid
                                }
                                width="60%"
                                mr="5%"
                            />

                            <ImageContainer
                                label="Gamers"
                                fluid={data.gamers.childImageSharp.fluid}
                                width="35%"
                                mb={{ _: '0%', lg: '5%' }}
                            />
                        </Flex>
                    </Flex>
                </ContentWidth>
            </LayoutSection>
        </ThemeProvider>
    );
};

const query = graphql`
    query AudienceQuery {
        fisher: file(relativePath: { eq: "images/home/audience/fisher.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        influencer: file(
            relativePath: { eq: "images/home/audience/influencer.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        music: file(relativePath: { eq: "images/home/audience/music.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        adventurer: file(
            relativePath: { eq: "images/home/audience/adventurer.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        musician: file(
            relativePath: { eq: "images/home/audience/artist-musician.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        lifestyle: file(
            relativePath: { eq: "images/home/audience/lifestyle-brand.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        outdoorsmen: file(
            relativePath: { eq: "images/home/audience/outdoorsmen.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        techEnthusiats: file(
            relativePath: { eq: "images/home/audience/tech-enthusiasts.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 420, srcSetBreakpoints: [350, 420]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        purse: file(
            relativePath: { eq: "images/home/audience/purse-lovers.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 180) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        gamers: file(relativePath: { eq: "images/home/audience/gamers.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
