import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { ContentWidth } from 'src/components/ContentWidth';

const Section = ({ num, image, title, children, isLast }) => (
    <Flex
        flexBasis={{ _: 'auto', lg: 280 }}
        mb={{ _: isLast ? 0 : 20, lg: 0 }}
        maxWidth={{ _: '90%', md: '500px', lg: '300px' }}
        flexGrow={0}
        flexShrink={1}
        paddingX="16"
        direction={{ _: 'column', sm: 'row', lg: 'column' }}
        alignItems="flex-start"
    >
        <Box mr={{ _: 0, sm: 10, lg: 0 }} mb={10} position="relative">
            <Img
                style={{
                    width: '80px',
                    position: 'absolute',
                    top: -15,
                    left: -35,
                }}
                fluid={image.childImageSharp.fluid}
            />

            <Box
                as="span"
                fontSize="heroHeading"
                fontWeight="bold"
                color="boldText"
                position="relative"
                zIndex={2}
            >
                {num}
            </Box>
        </Box>

        <Box>
            <Heading mb={6} fontSize="paragraphTitle" fontWeight="bold">
                {title}
            </Heading>

            <Paragraph>{children}</Paragraph>
        </Box>
    </Flex>
);

const query = graphql`
    query Home_HowWeHelp {
        image_01: file(relativePath: { eq: "images/01.png" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        image_02: file(relativePath: { eq: "images/02.png" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        image_03: file(relativePath: { eq: "images/03.png" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export const HowWeHelp = () => {
    const data = useStaticQuery(query);

    const sections = [
        {
            number: '01',
            title: 'partner with us to build or grow your community',
            content:
                'We like to build a long-term partnership to really understand your goals, pain points, and vision for your community.',
            image: data.image_01,
        },
        {
            number: '02',
            title: 'comprehensive strategy for growing your community',
            content:
                'Taking what we learned, we’ll deliver an exhaustive list of ideas and strategies to tackle each facet of your community.',
            image: data.image_02,
        },
        {
            number: '03',
            title: 'end to end management, from concept to go live',
            content:
                'With a plan in place, we start implementing green-light ideas and handle each detail until your new community goes live.',
            image: data.image_03,
        },
    ];

    return (
        <LayoutSection bg="secondaryBackground" my="0">
            <ContentWidth py={{ _: '6rem', lg: '6rem' }}>
                <Heading
                    mb={24}
                    textAlign="center"
                    fontSize="sectionHeading"
                    as="h3"
                >
                    how we can help your community
                </Heading>

                <Flex
                    direction={{ _: 'column', lg: 'row' }}
                    justify="center"
                    align={{ _: 'center', lg: 'flex-start' }}
                >
                    {sections.map((section, index) => (
                        <Section
                            key={index}
                            image={section.image}
                            num={section.number}
                            title={section.title}
                            isLast={index === sections.length - 1}
                        >
                            {section.content}
                        </Section>
                    ))}
                </Flex>
            </ContentWidth>
        </LayoutSection>
    );
};
