import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { Paragraph } from 'components/Paragraph';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { AutoplayWhenVisibleVideo } from 'src/components/Video';

const query = graphql`
    query homeVideoQuery {
        blackCircleTexture: file(
            relativePath: { eq: "images/home-hero/black-texture-circle.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export const VideoHero = () => {
    const data = useStaticQuery(query);

    return (
        <ThemeProvider defaultMode="dark">
            <Box
                backgroundColor="primaryBackground"
                position="relative"
                mb="4rem"
            >
                <AutoplayWhenVisibleVideo
                    video="/videos/characters-cut.mp4"
                    videoId="homeHero"
                    videoPoster="/videos/homevideoposter.jpg"
                    width="100%"
                    minHeight="500px"
                    maxHeight="80vh"
                    objectFit="cover"
                    objectPosition="center"
                />
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    backgroundColor="rgba(0,0,0,.5)"
                    display="flex"
                    alignItems="center"
                >
                    <ContentWidth>
                        <Box
                            maxWidth="540px"
                            style={{ flexDirection: 'row', zIndex: 2 }}
                        >
                            <Box
                                color="boldText"
                                fontWeight="medium"
                                fontSize={{ _: '3.6rem', md: 'heroHeading' }}
                                as="h1"
                                mb={10}
                                lineHeight="shorter"
                            >
                                build a beautiful, engaging community.
                            </Box>

                            <Paragraph
                                lineHeight={['tall', null, 'taller']}
                                color="boldText"
                                mb={10}
                            >
                                Community is where connecting happens — whether
                                you’re on YouTube, a forum, or at a digital
                                conference. We have the right strategy to help
                                your community grow and achieve your goals.
                            </Paragraph>

                            <Button fill="white" href="/contact" mr="12px">
                                Get started
                            </Button>

                            <Button
                                fill="white"
                                href="https://youtu.be/4qPMwg6F9ns"
                                target="_blank"
                            >
                                Watch Video
                            </Button>
                        </Box>
                    </ContentWidth>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
