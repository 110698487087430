import styled from '@emotion/styled';
import { Anchor } from 'components/Anchor';
import { Box } from 'components/Box';
import { Clickable } from 'components/Clickable';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { ThemeProvider } from 'components/ThemeProvider';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import React from 'react';
import { ContentWidth } from 'src/components/ContentWidth';

type SuccessCardProps = {
    fluidImage: any,
    heading: string,
    href: string,
};

const StyledSuccessCard = styled(Clickable)`
    background-color: ${props => props.theme.colors.primaryBackground};
    transition: 200ms ease;
    display: flex;
    flex-direction: column;

    &:hover {
        transform: scale(1.05);
    }
`;

function SuccessCard({ fluidImage, heading, href }: SuccessCardProps) {
    return (
        <StyledSuccessCard
            mb={{ _: 10, full: 0 }}
            //marginX={{ _: 8, full: 10 }}
            href={href}
            //width={{ _: '100%', sm: '80%', md: '355px' }}
            width={{ _: '100%', sm: '48%', lg: '31%' }}
        >
            {fluidImage && <Img fluid={fluidImage} />}

            <Box
                as={Anchor}
                href={href}
                p={{ _: 10, xl: 16 }}
                pb={{ _: 0, xl: 0 }}
            >
                <Heading fontSize="subtitle" as="h3">
                    {heading}
                </Heading>
            </Box>

            <Flex justify="flex-end">
                <Box
                    as="span"
                    padding={{ _: '1rem', xl: '2rem' }}
                    lineHeight="0"
                    href={href}
                    display="inline-block"
                    backgroundColor="secondaryBackground"
                >
                    <ArrowRightIcon size={24} />
                </Box>
            </Flex>
        </StyledSuccessCard>
    );
}

export function Success() {
    const data = useStaticQuery(query);

    return (
        <ThemeProvider defaultMode="dark">
            <LayoutSection hasPadding my="0">
                <ContentWidth>
                    <Heading
                        mb={{ _: 12, lg: 24 }}
                        fontSize="sectionHeading"
                        //center
                        as="h2"
                        textAlign="center"
                    >
                        our favorite ways to bring you success
                    </Heading>

                    <ThemeProvider defaultMode="light">
                        <Flex
                            direction={{ _: 'column', sm: 'row' }}
                            // mx={{ _: -8, lg: -10 }}
                            align={{ _: 'center', lg: 'flex-start' }}
                            justify="space-between"
                            wrap="wrap"
                        >
                            <SuccessCard
                                href="/principles/engagement"
                                heading="build a way to keep people coming back"
                                fluidImage={
                                    data.engagement.childImageSharp.fluid
                                }
                            />

                            <SuccessCard
                                href="/principles/monetization"
                                heading="increase revenue & user-generated content"
                                fluidImage={data.monetize.childImageSharp.fluid}
                            />

                            <SuccessCard
                                href="/principles/gamification"
                                heading="gamify your community & keep members engaged"
                                fluidImage={
                                    data.gamification.childImageSharp.fluid
                                }
                            />
                        </Flex>
                    </ThemeProvider>
                </ContentWidth>
            </LayoutSection>
        </ThemeProvider>
    );
}

const query = graphql`
    query SuccessSectionQuery {
        engagement: file(
            relativePath: { eq: "images/home/engagement-card.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 380, srcSetBreakpoints: [380, 600, 706]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        monetize: file(
            relativePath: { eq: "images/home/monetization-card.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 380, srcSetBreakpoints: [380, 600, 706]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        gamification: file(
            relativePath: { eq: "images/home/gamification-card.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 380, srcSetBreakpoints: [380, 600, 706]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
