import { isExternalUrl } from '@audentio/utils/src/isExternalUrl';
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useCallback } from 'react';
import { Box, BoxProps } from 'components/Box';

interface ClickableProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    href: string;
    /* element/component to use. div by default */
    as?: any;
}

export const Clickable =React.forwardRef((props: ClickableProps & BoxProps, ref) => {
    const location = useLocation();
    const {
        href,
        as: Element = 'div',
        onClick,
        ...rest
    } = props;

    const $onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (!href) return;

        if (onClick) {
            onClick(e);
        }

        const target = e.target as HTMLAnchorElement;

        // this part here will simulate click functionality 
        // on elements that are not anchor or button
        if (
            // not anchor
            !target.href &&
            !(target.parentNode as HTMLAnchorElement).href &&
            
            // not button
            target.nodeName !== 'BUTTON' &&
            target.parentNode.nodeName !== 'BUTTON'
        ) {
            const isExternal = isExternalUrl(href);

            if (e.metaKey && !isExternal) {
                // cmd+click internal link – open in new tab
                window.open(location.origin + href, '_blank');
            } else if (isExternal) {
                // external link always open in new tab
                window.open(href, '_blank');
            } else {
                // internal link
                // push to history
                navigate(href);
            }
        }
    }, []);


    return (
        <Box 
            as={Element}
            onClick={$onClick}
            ref={ref}
            cursor="pointer"
            {...rest}
        />
    );
});
