import { Anchor } from 'components/Anchor';
import { ContentWidth } from 'components/ContentWidth';
import { CTA } from 'components/CTA';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import { SmallSubtitle } from 'components/SmallSubtitle';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { AnimatedContainer } from 'src/components/AnimatedContainer';
import { Box } from 'src/components/Box';
import { Carousel } from 'src/components/Carousel';
import { useTheme } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';

const gql_TestimonialsQuery = graphql`
    query TestimonialsQuery {
        tomshardware: file(
            relativePath: { eq: "images/testimonials/tomshardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(
            relativePath: { eq: "images/testimonials/macrumors.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        ign: file(relativePath: { eq: "images/testimonials/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export function Testimonials() {
    const theme = useTheme();
    const [activeIndex, setActiveIndex] = useState(0);
    const { windowWidth } = useWindowResize();
    const data = useStaticQuery(gql_TestimonialsQuery);

    let slidesPerView: any = 1;
    let spaceBetween = 20;

    if (windowWidth > 1200) {
        slidesPerView = 'auto';
        spaceBetween = 30;
    }

    const moveTitle = activeIndex > 0 && windowWidth > 1280;

    return (
        <>
            <LayoutSection
                overflowX="hidden"
                // pt={{ _: '6rem', lg: '6rem', full: '14rem' }}
                // mt={{ _: '-6rem', lg: '-6rem', full: '-14rem' }}
                my="0"
                hasPadding
            >
                <ContentWidth>
                    <Flex direction={{ _: 'column', xl: 'row' }}>
                        <Flex
                            flexShrink={0}
                            justify={{ xl: 'center' }}
                            width={{ xl: '400px' }}
                            // pr={32}
                            mb={{ _: 10, xl: 0 }}
                        >
                            <Flex direction="column">
                                <AnimatedContainer
                                    isAnimated={moveTitle}
                                    offset={{
                                        initial: {
                                            y: 0,
                                        },
                                        final: {
                                            y: -120,
                                        },
                                    }}
                                >
                                    <Heading
                                        maxWidth={{ _: '100%', full: '220px' }}
                                        ml={{ xl: 10 }}
                                        mt={{ xl: 20 }}
                                        as="h2"
                                        fontSize="subsectionHeading"
                                    >
                                        what our clients are saying
                                    </Heading>
                                </AnimatedContainer>
                            </Flex>
                        </Flex>

                        <Flex direction="column">
                            <Carousel
                                onSlideChange={swiper => {
                                    setActiveIndex(swiper.activeIndex);
                                }}
                                // slideWidth="100%"
                                style={{
                                    flexDirection: 'column',
                                    width: '100%',
                                    overflow: 'visible',
                                }}
                                slidesPerView="auto"
                                spaceBetween={spaceBetween}
                                slides={[
                                    <Testimonial
                                        image={data?.tomshardware?.publicURL}
                                        source="Joe Pishgar, Director of Community"
                                        url="https://forums.tomshardware.com"
                                        urlLabel="Tom's Hardware"
                                    >
                                        “Ever eat at a really fantastic
                                        restaurant, and while you want to rave
                                        about it from the rooftops "This is SO
                                        GOOD!", a part of you wants to keep it
                                        as your little secret so that the place
                                        doesn't get too crowded? For me, for
                                        forum design and add-ons, that's
                                        Audentio. Mike and his team at Audentio
                                        have been the indispensable authority on
                                        forum software customization and themes
                                        for me for more than 3 years, and I
                                        cannot recommend them enough. I've
                                        leaned on Audentio's wisdom, the
                                        brilliance of elegant solutions Mike's
                                        team presents, and the clever - often
                                        critical! - features they create to
                                        bring to our communities.”
                                    </Testimonial>,
                                    <Testimonial
                                        image={data?.ign?.publicURL}
                                        source="Ginger Smith, Product Manager"
                                        url="https://ignboards.com"
                                        urlLabel="IGN Boards"
                                    >
                                        “We hired Audentio to build a strong
                                        user experience and handle the upgrade
                                        and migration for our (arguably) ancient
                                        community platform. We ended up with a
                                        new, slick look and fully responsive
                                        page that will allow for more
                                        consistency with our users. Audentio's
                                        deep knowledge of forum communities and
                                        XenForo's templates and plugins made the
                                        process quick and efficient. The test
                                        migrations and migration itself went
                                        smoothly, and required minimal
                                        follow-up. We will be contacting
                                        Audentio in the future as we figure out
                                        what other improvements we'd like to
                                        make!”
                                    </Testimonial>,
                                    <Testimonial
                                        image={data?.macrumors?.publicURL}
                                        source="Arnold Kim, Founder and Editorial Director"
                                        url="https://macrumors.com"
                                        urlLabel="MacRumors"
                                    >
                                        “Audentio has been a pleasure to work
                                        with. Not only is their work technically
                                        excellent, but they are also easy to
                                        communicate with and responsive to our
                                        needs and goals. We have been very happy
                                        with their work.”
                                    </Testimonial>,
                                ]}
                            />
                        </Flex>
                    </Flex>
                </ContentWidth>
            </LayoutSection>

            <CTA
                title="Ready to build a community?"
                buttonText="Contact us"
                href="/contact?source=homepage"
                mb="8rem"
            />
        </>
    );
}

function Testimonial({ image, source, children, url, urlLabel }) {
    return (
        <Flex
            // display="block"
            // width="100%"
            backgroundColor="secondaryBackground"
            p={12}
            direction="column"
        >
            <Anchor display="inline-flex" href={url} maxWidth={0}>
                <Box
                    as="img"
                    height="30px"
                    width="auto"
                    alt={urlLabel}
                    mb={4}
                    src={image}
                />
            </Anchor>
            <Flex
                mt={5}
                mb={10}
                maxWidth={{ _: '100%', xl: '70vw' }}
                direction={{ _: 'column', xl: 'row' }}
                justify={{
                    _: 'flex-start',
                    xl: 'space-between',
                }}
            >
                <SmallSubtitle mb={{ _: 5, xl: 0 }}>{source}</SmallSubtitle>

                <Anchor display="inline-flex" href={url}>
                    <SmallSubtitle color="boldText">{urlLabel}</SmallSubtitle>
                </Anchor>
            </Flex>

            <Paragraph lineHeight="taller">{children}</Paragraph>
        </Flex>
    );
}
