import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { Hero } from 'components/Hero';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import { SmallSubtitle } from 'components/SmallSubtitle';
import { ThemeProvider } from 'components/ThemeProvider';
import { graphql, useStaticQuery } from 'gatsby';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import React from 'react';

const ListItem = ({ children }) => (
    <Paragraph
        display="flex"
        flexDirection="row"
        alignItems="center"
        color="boldText"
        mt={6}
    >
        <Box as="span" color="boldText" mr={4} lineHeight={1}>
            <CheckCircleIcon size={24} />
        </Box>
        {children}
    </Paragraph>
);

export function FeaturedStory(props) {
    const data = useStaticQuery(gql_FeaturedStory);

    return (
        <ContentWidth>
            <LayoutSection>
                <ThemeProvider defaultMode="dark">
                    <Hero
                        image={
                            <video muted autoPlay loop>
                                <source
                                    src={'videos/syngates.mp4'}
                                    type="video/mp4"
                                />
                            </video>
                        }
                        imageOffset={{ bottom: '-20rem' }}
                        //mb={{ _: '0', md: '10rem'}}
                    >
                        <Flex
                            p={{ _: 16, lg: 32, full: 40 }}
                            direction="column"
                        >
                            <SmallSubtitle mb={4} color="bodyText">
                                Featured story
                            </SmallSubtitle>

                            <Heading fontSize="subsectionHeading" mb={10}>
                                Synyster Gates School
                            </Heading>

                            <Paragraph
                                lineHeight="taller"
                                mb={6}
                                color="bodyText"
                                maxWidth="600px"
                            >
                                The guitarist from Avenged Sevenfold wanted to
                                create a community where aspiring and fellow
                                guitarists could encourage and learn together by
                                offering free online lessons. Working together
                                to build his vision, members can:
                            </Paragraph>

                            <ListItem>
                                Take lessons from beginner to master
                            </ListItem>

                            <ListItem>
                                Have discussions with other members and ask
                                questions
                            </ListItem>

                            <ListItem>Earn unique achievements</ListItem>

                            <ListItem>
                                Financially contribute to the community in
                                several ways
                            </ListItem>
                            <ListItem>Share videos and pictures</ListItem>
                            <ListItem>Participate in contests</ListItem>

                            <Button
                                fill="white"
                                href="/contact"
                                mt="10"
                                maxWidth="150px"
                            >
                                build your own
                            </Button>
                        </Flex>
                    </Hero>
                </ThemeProvider>
            </LayoutSection>
        </ContentWidth>
    );
}

const gql_FeaturedStory = graphql`
    query FeaturedStory {
        hero: file(relativePath: { eq: "images/home/syngates-hero.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 550, srcSetBreakpoints: [550, 850, 1100]) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
